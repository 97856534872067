// External Imports
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { graphql, navigate } from "gatsby";
import debounce from "lodash.debounce";
// Components
import {
  Grid,
  Box,
  Pagination,
  Container,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  BlogsCallToAction,
  PanelWrapper,
  Breadcrumb,
  DrawerSidebar,
  SkeletonCard,
  SEO,
} from "../../../exports/components.exports";
// Config
import { colors } from "../../../exports/assets.exports";
import { ILinkOptionProps } from "../../../exports/types.exports";
import { urlRoutes } from "../../../exports/constants.exports";
import AllBlogs from "./AllBlogs";

/**
 * @constant query for strapi
 */
export const query = graphql`
  query blogsPageQuery($skip: Int!, $limit: Int!) {
    allStrapiPost(
      sort: { fields: updated_at, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          description
          slug
          feature_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          DynamicContent
          post_category {
            slug
            Name
          }
          tags {
            title
          }
          updated_at(fromNow: true)
          created_at(formatString: "YYYY/MM/DD")
          writer {
            name
            picture {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    allStrapiPostCategory {
      edges {
        node {
          slug
          Name
        }
      }
    }
    allBlogs: allStrapiPost {
      edges {
        node {
          title
          description
          slug
          feature_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          DynamicContent
          post_category {
            slug
            Name
          }
          tags {
            title
          }
          updated_at(fromNow: true)
          created_at(formatString: "YYYY/MM/DD")
          writer {
            name
            picture {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * @function DocsPage
 * @returns the blogs page content - this is the base or index blogs page where all blogs are available, NO CATEGORY, url: /blogs
 */
const BlogsPage = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const posts = data.allStrapiPost.edges;
  const postCategories = data.allStrapiPostCategory.edges;
  const allBlogs = data.allBlogs.edges;
  const { currentPage, numPages } = pageContext;
  const [searchQuery, setSearchQuery] = useState("");
  const searchFields = ["title", "description"]; // the keys on allGuides.edges.node
  const [filteredBlogs, setFilteredBlogs] = useState<any>();
  const [isSearching, setIsSearching] = useState(false);

  const seo = {
    metaTitle: `Swiftcase - All Blog Posts - Page ${currentPage}`,
    metaDescription:
      "A paginated list of all of Swiftcase's blog posts from over the last few years.",
  };

  /**
   * @function handlePaginationChange
   * @param value the page number to navigate to
   * @returns the navigation from gatsby
   */
  const handlePaginationChange = (_: any, value: number) => {
    if (value === 1) {
      navigate(`${urlRoutes.BLOG}/`);
    } else {
      navigate(`${urlRoutes.BLOG}/${value}/`);
    }
  };

  /**
   * @function renderOptions
   * @returns the list of links for the drawer sidebar
   */
  const renderOptions = () => {
    const dropdownOptions: Array<ILinkOptionProps> = [];

    postCategories.map((category: any) =>
      dropdownOptions.push({
        href: `${urlRoutes.BLOG}/${category.node.slug}`,
        text: category.node.Name,
      })
    );

    return dropdownOptions;
  };

  /**
   * @function debounceCallback debounced callback
   * to set isSearching to true
   * sets the query string state
   */
  const debounceCallback = debounce((query: string) => {
    setIsSearching(true);
    setSearchQuery(query);
  }, 1000);

  /**
   * @function handleSearchCallback
   * @param query passed back up from the call to action (search input field)
   */
  const handleSearchCallback = (query: string) => {
    debounceCallback(query);
  };

  /**
   * @function debouncedFilter
   * a debounced function to filter the results
   * filters allGuides then uses the searchFields constant (an array of the key's on the object)
   * to see if the searchQuery string is included on any of those fields
   */
  const debouncedFilter = useCallback((searchQuery) => {
    let filterSearchBlogs = allBlogs.filter((blog: any) =>
      searchFields.some((key: string) =>
        blog.node[key].toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

    let blogTagResults = allBlogs.filter((blog: any) =>
      blog.node.tags
        ?.map((tag: any) => tag.title)
        .join(", ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );

    filterSearchBlogs = [...filterSearchBlogs, ...blogTagResults];

    setIsSearching(false);
    setFilteredBlogs(filterSearchBlogs);
  }, []);

  /**
   * @function useEffect to handle the search query and debounce call to filter results
   * if empty string empty the filtered results, set isSearching to false
   * else call the debounced filter method
   */
  useEffect(() => {
    if (searchQuery === "") {
      setFilteredBlogs(undefined);
      setIsSearching(false);
    } else {
      debouncedFilter(searchQuery);
    }
  }, [searchQuery, debouncedFilter]);

  return (
    <Fragment>
      <SEO seo={seo} />
      <BlogsCallToAction
        handleSearchCallback={handleSearchCallback}
        showSearchBar
      />
      <Box sx={{ backgroundColor: colors.swiftcaseWebsiteLightGrey }}>
        <Container
          maxWidth="lg"
          sx={{ backgroundColor: colors.swiftcaseWebsiteLightGrey }}
        >
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginY: "2rem",
            }}
          >
            <Breadcrumb currentPage="Blogs" />
            <Box>
              <DrawerSidebar listItems={renderOptions()} />
            </Box>
          </Box>
        </Container>
        <Divider />
        <PanelWrapper
          contentWidth="lg"
          minHeight="100vh"
          backgroundColor={colors.swiftcaseWebsiteLightGrey}
        >
          {!isSearching ? (
            <Grid
              container
              className="post-card-grid"
              sx={{ justifyContent: "flex-start" }}
              spacing={4}
            >
              {filteredBlogs?.length === 0 ? (
                <Grid
                  item
                  xs={12}
                  className="guide-category__no-blogs"
                  sx={{ justifyContent: "center" }}
                >
                  <SkeletonCard
                    noAction
                    alternateMessage={`No search result's matched; "${searchQuery}"`}
                  />
                </Grid>
              ) : (
                <AllBlogs
                  posts={
                    searchQuery !== "" && filteredBlogs ? filteredBlogs : posts
                  }
                />
              )}
              {searchQuery === "" && (
                <Grid
                  item
                  className="article-grid__grid-item"
                  xs={12}
                  sx={{ marginTop: "2rem" }}
                >
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={numPages}
                    page={currentPage}
                    boundaryCount={2}
                    onChange={handlePaginationChange}
                    className="articles-grid__pagination"
                    sx={{
                      width: "fit-content",
                      marginX: "auto",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ color: colors.swiftcaseMediumBlue }} />
            </Box>
          )}
        </PanelWrapper>
      </Box>
    </Fragment>
  );
};

export default BlogsPage;
